import React from 'react';
import {getFacilitatorToken} from '../../../AxiosActions.js';
import LoginView from './LoginView.jsx';


export default class AdminLoginView extends LoginView {
    getToken(){
        let sent_data = {
            username: this.state.username,
            password: this.state.password,
        };
        getFacilitatorToken(sent_data).then(res => {
            let token = res.data.token;
            let game_id = res.data.game_id;

            if (token !== null) {
                let d = new Date();
                d.setTime(d.getTime() + (3*60*60*1000));
                document.cookie = 'facilitator_TOKEN' + '=' + token + '; expires=' + d.toGMTString() + ';';
                this.props.logIn(game_id);
            }
        }).catch(error => {
            //wrong credentials
            if (error.response.status === 400) {
                 this.setState({displayLoginError: true});
            }
        });
    }
}