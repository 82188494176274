import React from 'react';
import LanguageSelection from './LanguageSelection.jsx';
import LoginView from './LoginView.jsx';
import {Redirect} from 'react-router-dom';

function getCookie(name) {
    let value = "; " + document.cookie;
	let parts = value.split("; " + name + "=");
	if (parts.length == 2)
        return parts.pop().split(";").shift();
}

export default class CheckLogin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            logged: false,
            active_game_id: null,
            redirectToFrontPage: false,
        };
        this.logFacilitatorIn = this.logFacilitatorIn.bind(this);
        this.logFacilitatorOut = this.logFacilitatorOut.bind(this);
    }

    logFacilitatorIn(game_id=null) {
        this.setState({logged: true});
        if (game_id !== null) {
            this.setState({active_game_id: game_id});
        }
    }

    logFacilitatorOut() {
        if (document.cookie.indexOf('facilitator_TOKEN=') !== -1){
            document.cookie = 'facilitator_TOKEN=' +'=; Max-Age=-99999999;';
        }
        this.setState({
            logged: false,
            redirectToFrontPage: true
        });

    }

    render(){
        let content;

        if (document.cookie.indexOf('facilitator_TOKEN=') !== -1) {
            this.state.logged = true;
		}

        if (this.state.redirectToFrontPage) {
              return <Redirect push to={{pathname: '/', state:{}}} />;
        }

        else if (this.state.logged == false) {
            content = (<LoginView
                logIn={this.logFacilitatorIn}
            />);
        }
        else if (this.state.active_game_id !== null) {
            return <Redirect
                push to={
                    {pathname: '/game', state:{
                        user_type: 'facilitator',
                        instance_id: this.state.active_game_id
                    }}}
            />;
        }
        else {
            content = (<LanguageSelection
                logOut={this.logFacilitatorOut}
            />);
        }
        return (
            <div>
                {content}
            </div>
        );
    }
}