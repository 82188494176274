import React from 'react';
import LanguageSelection from './LanguageSelection.jsx';
import CheckLogin from './CheckLogin.jsx';
import AdminLoginView from './AdminLoginView.jsx';
import {Redirect} from 'react-router-dom';

function getCookie(name) {
    let value = "; " + document.cookie;
	let parts = value.split("; " + name + "=");
	if (parts.length == 2)
        return parts.pop().split(";").shift();
}

export default class AdminCheckLogin extends CheckLogin {
    render(){
        let content;
        if (document.cookie.indexOf('facilitator_TOKEN=') !== -1) {
            this.state.logged = true;
		}
        if (this.state.redirectToFrontPage) {
              return <Redirect push to={{pathname: '/', state:{}}} />;
        }
        else if (this.state.logged == false) {
            content = (<AdminLoginView
                logIn={this.logFacilitatorIn}
            />);
        }
        else if (this.state.active_game_id !== null) {
            return <Redirect
                push to={
                    {pathname: '/game', state:{
                        user_type: 'facilitator',
                        instance_id: this.state.active_game_id
                    }}}
            />;
        }
        else {
            content = (<LanguageSelection
                logOut={this.logFacilitatorOut}
            />);
        }
        return (
            <div>
                {content}
            </div>
        );
    }
}