export const FINNISH_STATE = {
    lang: 'fi',
    messages: {

        //PLAYER VIEW MESSAGES START
        'app.gameselection.title': 'Pulmikaspeli',
        'app.gameselection.gameid': 'Pelin tunnus',
        'app.gameselection.instruction': 'Kirjoita yhteisellä näytöllä oleva tunnus',

        'app.login.title': "Kirjaudu sisään",
        'app.login.logout': "Kirjaudu ulos",
        'app.login.wrongcredentials': "Virheellinen tunnus tai salasana!",
        'app.login.password': 'salasana',
        'app.login.username': 'tunnus',

        'player.groupselection.title': 'Valitse joukkue',
        'player.drawavatar.title': 'Piirrä',
        'player.drawavatar.instruction': 'Piirrä itsellesi hahmokuva',
        'player.namegroup.title': 'Joukkueen nimi',
        'player.namegroup.instruction': 'Keksikää yhdessä joukkueellenne nimi',

        //Fact question
        'player.factquestion.answer.title': 'Anna vastauksesi',
        'facilitator.factquestion.answer': 'Odota että kaikki pelaajat vastaavat.',
        'facilitator.factquestion.result': 'Pelaajat katsovat oikeaa vastausta.',

        //Open question
        'player.openquestion.answerscreen.placeholder': 'Maksimipituus: 100 merkkiä',
        'screen.openquestion.answerscreen.choosebest' : 'Valitkaa mielestänne paras vastaus seuraavista vaihtoehdoista',
        'player.openquestion.answerscreen.voteinstruction': 'Valitkaa paras vastaus',
        'facilitator.openquestion.answerscreen.answer': 'Odota kun pelaajat keskustelevat vihjeistä ja kirjoittavat vastauksen.',

        //Ending
        'player.ratescreen.title': 'Kiitos pelistä',
        'player.ratescreen.instruction': 'Millaisen arvosanan antaisit pelille?',
        //PLAYER VIEW MESSAGES END


        //FACILITATOR VIEW MESSAGES START
        //Exit
        'facilitator.gameexit.confirmation': 'Oletko varma, että haluat lopettaa pelin?',
        'facilitator.logout.confirmation': 'Oletko varma, että haluat kirjautua ulos?',
        //Game select
        'facilitator.gameselect.title': 'Valitse peli',

        //Grouping
        'facilitator.groupsize.title': 'Montako joukkuetta?',
        'facilitator.groupsize.instruction': 'Suosittelemme joukkuekooksi 3-4 pelaajaa. Joukkueita voi olla enimmillään 6',
        'facilitator.groupmoderation.title': 'Moderointi',
        'facilitator.groupmoderation.instruction': 'Joukkuetta valmiina',
        'facilitator.groupmoderation.namereset': 'Nollaa nimi',
        'facilitator.groupmoderation.info': 'Pelaajat liittyvät peliin. Aloita peli, kun kaikki ovat mukana.',

        //Facilitator wait screen
        'facilitator.waitscreen.read': 'Pelaajat lukevat. Voit myös lukea tekstin ääneen.',
        'facilitator.waitscreen.discuss': 'Pelaajat keskustelevat kysymyksestä.',
		'facilitator.waitscreen.multiquestion.discuss': 'Pelaajat keskustelevat. Kun siirryt eteenpäin, he saavat vastausvaihtoehdot ja vihjeitä.',
		'facilitator.waitscreen.openquestion.discuss': 'Pelaajat keskustelevat. Kun siirryt eteenpäin, he saavat vastauskentän ja vihjeitä.',
        'facilitator.waitscreen.debatewheel': 'Perusteluvuoro arvotaan.',
        'facilitator.waitscreen.voteresults': 'Pelaajat katsovat äänestyksen tuloksia.',
        'facilitator.waitscreen.debateresults': 'Pelaajat katsovat tuloksia.',

        //Big screen setup
        'facilitator.setupbigscreen.title': 'Yhteinen näyttö',
        'facilitator.setupbigscreen.subtitle': 'Avaa pelinäkymä menemällä osoitteeseen:',
        'facilitator.setupbigscreen.description': 'pulmikas.fi/screen',
        'facilitator.setupbigscreen.screencode': 'Pelin tunnus',
        'facilitator.setupbigscreen.instructiontitle': 'Ohjeet:',
        'facilitator.setupbigscreen.instructioncontent-1': 'Pelin yhteisen näkymän tulee näkyä kaikille pelaajille.',
        'facilitator.setupbigscreen.instructioncontent-2': 'Jos tämän laitteen kuva voidaan näyttää kaikille pelaajille, avaa osoite pulmikas.fi/screen eri selainikkunaan.',
        'facilitator.setupbigscreen.instructioncontent-3': 'Jos käytät yhteisen näytön esittämiseen toista laitetta, mene sillä osoitteeseen: pulmikas.fi/screen ja anna avautuvaan ikkunaan tunnus.',

        //Fact question
        'facilitator.factquestion.answermoderation.answers': 'vastausta',

        //Multi question
        'facilitator.multiquestion.answerreset': 'Poista vastaus',
        'facilitator.multiquestion.answer': 'Vastaus: ',
        'facilitator.multiquestion.answerscreen.waitingforanswer': 'Odotetaan vastausta',
        'facilitator.multiquestion.answerscreen.ready': 'Valmis',
        'facilitator.multiquestion.debatemoderation.title': 'Perustelu',
        'facilitator.multiquestion.debatemoderation.speaking': 'Äänessä joukkue:',
        'facilitator.multiquestion.debatemoderation.accepted': 'Hyväksytty',
        'facilitator.multiquestion.debatemoderation.declined': 'Hylätty',
        'facilitator.multiquestion.debatemoderation.instruction': 'Kuuntele joukkueen perustelu',
        'facilitator.multiquestion.answer.answer': 'Odota kun pelaajat keskustelevat vihjeistä ja valitsevat vastauksen.',
        'facilitator.multiquestion.answer.result': 'Pelaajat katsovat tuloksia.',
        //FACILITATOR VIEW MESSAGES END

        //SCREEN VIEW MESSAGES START

        //Start
        'screen.welcomescreen.title': 'Tervetuloa',
        'screen.welcomescreen.instruction': ' Mene osoitteeseen pulmikas.fi',

        //Fact question
        'screen.factquestion.answerScreen.title': 'Oikea vastaus',
        
        //Multiple choice question
        'screen.multiquestion.debatescreen.instruction': 'Kertokaa, miksi mielestänne teidän valitsemanne vastaus oli paras vaihtoehdoista',

        //Open question
        'screen.openquestion.debatescreen.instruction': 'Perustelkaa oman joukkueenne vastausta',
        'facilitator.openquestion.vote': 'Pelaajat äänestävät parasta vastausta.',

        
        //Debate views
        'screen.debatewheel.title': 'Kuka saa perustella?',
        //SCREEN VIEW MESSAGES END

        //Universal
        'thanksforplaying': "Kiitos pelaamisesta!",
        'startbutton': 'Valitse peli',
        'startgamebutton': 'Aloita peli',
        'startnewgame': 'Aloita uusi peli',
        'logout': 'Kirjaudu ulos',
        'readybutton': 'Valmis',
        'nextbutton': 'Seuraava',
        'group': 'Joukkue',
        'wait': 'Odota',
        'yes': 'Kyllä',
        'no': 'Ei',
        'hint': 'Vihje',
        'discuss': 'Keskustelkaa',
        'listen': 'Kuunnelkaa',
        'debate': 'Perustelkaa',
        'forward': 'Eteenpäin',
        'send': 'Lähetä',
        'press1second': 'Paina puoli sekuntia',
        'waitMoment': 'Odota hetki',
        'errorMessage': 'Jokin meni vikaan, yritä kirjautumista uudelleen'
    }
  };