import { FINNISH_STATE } from "static/locales/fi.js";
import { SWEDISH_STATE } from "static/locales/se.js";
import { ENGLISH_STATE } from "static/locales/en.js";

const confirmationMessage = (languageCode, translationKey) => {
    switch (languageCode) {
        case "fi":
            return FINNISH_STATE.messages[translationKey];
        case "se":
            return SWEDISH_STATE.messages[translationKey];
        case "en":
            return ENGLISH_STATE.messages[translationKey];
        default:
            return FINNISH_STATE.messages[translationKey];
    }
};

export default confirmationMessage;
