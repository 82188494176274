import React from 'react';
import hourglass from "static/svg/hourglass.svg";
import arrowUndo from "static/svg/arrow-undo.svg";
import arrowLeft from "static/svg/arrow-left.svg";
import {FormattedMessage} from 'react-intl'

import {
    Container,
    Row,
    Col,
    Button
} from 'reactstrap';

class PlayerGroupBuildingHandler extends React.Component {
    render() {
        let view = null;
        // get the view name from the view string
        let viewName = this.props.information.view.split('.').slice(-1)[0];
		switch(viewName) {
			case 'selectGroup':
				view = (
				    <SelectGroup
                        information={this.props.information}
                        sendSocketMessage={this.props.sendSocketMessage}
                    />
                );
				break;
            case 'drawAvatar':
                view = (
                    <DrawAvatar
                        information={this.props.information}
                        sendSocketMessage={this.props.sendSocketMessage}
                    />
                );
                break;

            case 'nameGroup':
                view = (
                    <NameGroup
                        information={this.props.information}
                        sendSocketMessage={this.props.sendSocketMessage}
                    />
                );
                break;

            case 'waitingForStart':
                view = (
                    <WaitingForStart
                        information={this.props.information}
                        sendSocketMessage={this.props.sendSocketMessage}
                    />
                );
                break;
            default:
				view = null;
		}
        return (<div className="app-container">{view}</div>);
	}
}

class SelectGroup extends React.Component {
    constructor(props) {
        super(props);
        this.sendSelection = this.sendSelection.bind(this);
        this.state = {
            sent: false
        }
    }
    sendSelection(index) {
        this.props.sendSocketMessage({
            command: 'groupBuilding.addPlayerToGroup',
            data: {
                groupIndex: index
            }
        });
        this.setState({sent: true});
    }

    renderTeamName(number) {
        let name;
        if(this.props.information.data.groups[number].name) {
            name = this.props.information.data.groups[number].name;
            if(name.length > 13){
                name = name.slice(0,13) + '...'
            }
        }
        else{
            name =  number;
        }
        return name +  " (" + Object.keys(this.props.information.data.groups[number]['players']).length  + ")"
    }

    
    render(){
        let groupButtons;
        let { groups } = this.props.information.data;

        groupButtons = Object.keys(groups).map((number,i) =>{
            let group_text = "";
            if(groups[number].name === null){
                group_text = <FormattedMessage id='group'/>;
            }
            return(
                <div key={'row'+String(i)} className="row button-row">
                    <div key={'col'+String(i)} className="col-md align-middle">
                    <Button className="group-button" key = {i} disabled = {this.state.sent} onClick = {() => this.sendSelection(number)} >
                        {group_text} {this.renderTeamName(number)}
                    </Button>
                    </div>
                </div>
            )
        });
        return (
            <div className="container-fluid" style={{overflow: 'auto'}}>
                <div className="row">
                    <div className="title-container col-md">
                        <h1 className="app-title"><FormattedMessage id='player.groupselection.title'/></h1>
                    </div>
                </div>
                {groupButtons}
            </div>
        );
    }
}

function preventTouchMove(e) {
    e.preventDefault();
}

class DrawAvatar extends React.Component {
    removePlayerFromGroup() {
        this.props.sendSocketMessage({
            command: 'groupBuilding.removePlayerFromGroup',
            data: {}
        });
    }

    resetAvatar() {
        this.canvas.resetAvatar();
    }

    sendAvatar() {
        this.canvas.sendAvatar()
    }

    componentDidMount() {
        document.addEventListener("touchmove", preventTouchMove, false);
    }

    componentWillUnmount() {
        document.removeEventListener("touchmove", preventTouchMove, false);
    }

    render() {

        let group = "";
        let {groups} = this.props.information.data;
        let player_id = this.props.information.data.player_id;
        let group_index = "";

        for(var x in groups){
            for(var id in groups[x].players){
                if(id == player_id){
                    if(groups[x].name == null){
                        group = <FormattedMessage id='group'/>
                        group_index = x;
                    } else {
                        group = groups[x].name
                    }
                    break;
                }
            }
        }
        

        return (
            <div className="container-fluid" style={{overflow: 'auto'}}>
                <span className="back-button" onClick={this.removePlayerFromGroup.bind(this)}><img style={{width:"100%", height:"100%"}} src={arrowLeft}/></span>
                <div className="row">
                        <div className="col-md">
                            <p className="app-paragraph" style={{textAlign:'right'}}>{group} {group_index}</p>
                        </div>
                </div>
                <div className="row">
                        <div className="title-container col-md" style={{paddingTop:0, marginBottom:0,}}>
                            <h1 className="app-title"><FormattedMessage id='player.drawavatar.title'/></h1>
                        </div>
                </div>
                <div className="row" style={{paddingBottom:'1em'}}>
                    <div className="col-md">
                        <p className="app-paragraph"><FormattedMessage id='player.drawavatar.instruction'/></p>
                    </div>
                </div>
                <DrawingCanvas
                    onRef={ref => (this.canvas = ref)}
                    sendSocketMessage={this.props.sendSocketMessage}
                />
                <div className="row" style={{paddingBottom:'1em'}}>
                    <div className="col-md">
                        <button className="orange-button" onClick={this.sendAvatar.bind(this)} ><FormattedMessage id='readybutton'/></button>
                    </div>
                </div>
            </div>
        );
    }
}

class DrawingCanvas extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            canvasResized: false,
            paint: false,
            clickX: new Array(),
            clickY: new Array(),
            clickDrag: new Array(),
      };

      //This bindings
      this.sendAvatar = this.sendAvatar.bind(this);
      this.resetAvatar = this.resetAvatar.bind(this);
      this.handleOnMouseDown = this.handleOnMouseDown.bind(this);
      this.handleonMouseLeave = this.handleonMouseLeave.bind(this);
      this.handleOnMouseMove = this.handleOnMouseMove.bind(this);
      this.handleOnMouseUp = this.handleOnMouseUp.bind(this);
    }
    componentDidMount() {
        this.redraw();
        this.props.onRef(this);
    }

    componentWillUnmount(){
        this.props.onRef(undefined);
    }


    redraw() {
        var context = this.refs.canvas.getContext('2d');
        context.clearRect(0, 0, context.canvas.width, context.canvas.height); // Clears the canvas

        context.strokeStyle = "#4b4b4b";
        context.lineJoin = "round";
        context.lineWidth = 8;

        for (var i = 0; i < this.state.clickX.length; i++) {
            context.beginPath();
            if (this.state.clickDrag[i] && i) {
                context.moveTo(this.state.clickX[i - 1], this.state.clickY[i - 1]);
            }
            else {
                context.moveTo(this.state.clickX[i] - 1, this.state.clickY[i]);
            }
            context.lineTo(this.state.clickX[i], this.state.clickY[i]);
            context.closePath();
            context.stroke();
        }
    }

   addClick(x, y, dragging) {
        let newClickX = this.state.clickX.slice();
        let newClickY = this.state.clickY.slice();
        let newClickDrag = this.state.clickDrag.slice();

        newClickX.push(x);
        newClickY.push(y);
        newClickDrag.push(dragging);
        this.setState({
            clickX: newClickX.slice(),
            clickY: newClickY.slice(),
            clickDrag: newClickDrag.slice(),
      });

    }

   handleOnMouseDown(e){
      let canvas = document.getElementById('canvas');
      if(!this.state.canvasResized){
          canvas.height = canvas.offsetHeight;
          canvas.width = canvas.offsetWidth;
          this.state.canvasResized = true;
      }
      let rect = canvas.getBoundingClientRect();
      this.setState({
          paint: true,
      });
      this.addClick(e.clientX - rect.left, e.clientY - rect.top);
      this.redraw();
    }


   handleOnMouseMove(e){
        let canvas = document.getElementById('canvas');
        let rect = canvas.getBoundingClientRect();
        let x;
        let y;

        if(e.touches){
            x = e.touches[0].clientX
            y = e.touches[0].clientY
        }
        else{
            x = e.clientX;
            y = e.clientY;
        }

      if(this.state.paint){
        this.addClick(x - rect.left, y - rect.top, true);
        this.redraw();
      }

   }

   handleOnMouseUp(e) {
      this.setState({
          paint: false,
      });
      this.redraw();
   }

   handleonMouseLeave(e){
        this.setState({
          paint: false,
      });
    }

   resetAvatar(){
       this.setState({
            clickX: new Array(),
            clickY: new Array(),
            clickDrag: new Array(),
       });
       var context = this.refs.canvas.getContext('2d');
       context.clearRect(
           0,
           0,
           context.canvas.width,
           context.canvas.height
       );
   }

   sendAvatar() {
       this.props.sendSocketMessage({
           command: 'groupBuilding.addPlayerAvatar',
           data: {
               avatar_src: this.refs.canvas.toDataURL()
           }
       });
   }

  render(){
    return (
        <div className="row">
            <div className="col-md">
          <canvas id="canvas" className="avatar-canvas" ref="canvas"
              onMouseDown = {this.handleOnMouseDown}
              onMouseMove = {this.handleOnMouseMove}
              onMouseUp = {this.handleOnMouseUp}
              onMouseLeave = {this.handleonMouseLeave}
              onTouchStart = {this.handleOnMouseDown}
              onTouchMove = {this.handleOnMouseMove}
              onTouchEnd = {this.handleOnMouseUp}

          >        
          </canvas>
          <span className="undo-button" onClick={this.resetAvatar} ><img style={{width:"100%", height:"100%"}} src={arrowUndo}/></span>
          </div>
        </div>
    );
  }
}

class NameGroup extends React.Component {
    constructor(props) {
        super(props);
        this.sendName = this.sendName.bind(this);
        this.updateState = this.updateState.bind(this);
        this.inputBlur = this.inputBlur.bind(this);

        this.state = {
            data: '',
            sent: false
        };

    }

    inputBlur(e) {
        if (e.key === 'Enter') {
            e.target.blur();
        }
    }

    removePlayerAvatar() {
        this.props.sendSocketMessage({
            command: 'groupBuilding.removePlayerAvatar',
            data: {}
        });
    }

    sendName() {
        this.props.sendSocketMessage({
            command: 'groupBuilding.assignGroupName',
            data: {
                groupName: this.state.data
            }
        });
        this.setState({sent: true});
    }

    updateState(e) {
      this.setState({data: e.target.value});
    }

    render() {

        let group = "";
        let {groups} = this.props.information.data;
        let player_id = this.props.information.data.player_id;
        let group_index = "";

        for(var x in groups){
            for(var id in groups[x].players){
                if(id == player_id){
                    if(groups[x].name == null){
                        group = <FormattedMessage id='group'/>
                        group_index = x;
                    } else {
                        group = groups[x].name
                    }
                    break;
                }
            }
        }
        
        return (
            <div className="container-fluid scrollable-container">
                <span className="back-button" onClick={this.removePlayerAvatar.bind(this)}><img style={{width:"100%", height:"100%"}} src={arrowLeft}/></span>
                <div className="row">
                    <div className="col-md">
                        <p className="app-paragraph" style={{textAlign:'right'}}>{group} {group_index}</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md">
                        <h1 className="app-title"><FormattedMessage id='player.namegroup.title'/></h1>
                    </div>
                </div>
                <div className="row" style={{paddingBottom:'1em'}}>
                    <div className="col-md">
                        <p className="app-paragraph" style={{fontSize:'5em', marginLeft:'12%', marginRight:'12%', marginTop:'1em' }}> <FormattedMessage id='player.namegroup.instruction'/> </p>
                    </div>
                </div>
                <div className="row">
                    <div className="d-flex justify-content-center col-md">
                        <FormattedMessage id='group'>
                                {(msg) => ( <input className="group-name" type="text" style={{marginTop:'1em'}} onKeyPress={(e) => this.inputBlur(e)} maxLength={20} onChange = {this.updateState}
                                                   placeholder ={msg + " " + group_index}   />)}
                        </FormattedMessage>
                    </div>
                </div>
                <div className="row" style={{paddingBottom:'1em', marginTop:'50%'}}>
                    <div className="col-md">
                        <button className="orange-button" disabled={this.state.sent} onClick={this.sendName}><FormattedMessage id='readybutton'/></button>
                    </div>
                </div>
            </div>
        );
    }
}

class WaitingForStart extends React.Component {
    removePlayerAvatar() {
        this.props.sendSocketMessage({
            command: 'groupBuilding.removePlayerAvatar',
            data: {}
        });
    }

    render() {
        let group = "";
        let {groups} = this.props.information.data;
        let player_id = this.props.information.data.player_id;
        let group_index = "";
        for(var x in groups){
            for(var id in groups[x].players){
                if(id == player_id){
                    if(groups[x].name == null){
                        group = <FormattedMessage id='group'/>
                        group_index = x;
                    } else {
                        group = groups[x].name
                    }
                    break;
                }
            }
        }
        return (
            <div className="container-fluid">
            <span className="back-button" onClick={this.removePlayerAvatar.bind(this)}> <img style={{width:"100%", height:"100%"}} src={arrowLeft}/></span>
            <div className="row">
                    <div className="col-md">
                        <p className="app-paragraph" style={{textAlign:'right'}}>{group} {group_index}</p>
                    </div>
            </div>
            <div className="row">
                    <div className="title-container col-md" style={{paddingTop:0, marginBottom:0,}}>
                        <h1 className="app-title"><FormattedMessage id='wait' /></h1>
                    </div>
            </div>
            <div className="row" style={{marginTop:'25%'}}>
                <img className="hourglass" src={hourglass}/>
            </div>
        </div>
        );
    }
}


export default PlayerGroupBuildingHandler;