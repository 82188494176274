import React, {Component} from 'react';
import select from "static/svg/select.svg";
import {FormattedMessage} from 'react-intl';
import ScreenStepper from '../../../../utils/ScreenStepper.jsx'
import Pulmikas from '../../../../utils/Pulmikaslogo.jsx';

export default class QuestionScreen extends Component {
    constructor(props) {
        super(props);
    }
    render(){
        let {data} = this.props.information;
        let {groups} = this.props.information.data;
        let scoreRow;

        let optionsContent = Object.keys(data.choices).sort().map( (key, index)=> {
            return (
                <div className="d-flex justify-content-center col-md" style={{paddingTop:'3rem'}}>
                    <div className="screen-option-container">
                        <p className="screen-option-index">{key}</p>
                        <p className="screen-option-text"> {data.choices[key].choice_text} </p>
                    </div>
                </div>
            )
        });
        scoreRow = Object.keys(groups).map((index) => {
            let group = groups[index];
            let group_text = "";
            let name = group.name;

            if(group.name === null || group.name == ""){
                group_text = <FormattedMessage id = "group"/> 
                name = index
            }

            if(name.length > 13){
                name = name.slice(0,11)+'...'
            }

            return(
                <p className="screen-group-small"> {group_text} {name} <span className="score"> {group.score} </span> </p>
            )

        });

        return (
            <div className="screen-container">
                <div className="container-fluid d-flex flex-column">
                    <p className="screen-gameid-small"> <FormattedMessage id = "app.gameselection.gameid"/>: {this.props.information.data.player_code} </p>
                    <div className="row">
                        <div className="d-flex justify-content-center col-md">
                            {scoreRow}
                        </div>
                    </div>
                    <div className="d-flex flex-column justify-content-center" style={{flex:'1'}}>
                        <div className="row" style={{marginTop:'5rem'}} >
                            <div className="d-flex justify-content-center col-md">
                                <p className="screen-paragraph-small" style={{width:'70%'}} >{data.description}</p>
                            </div>
                        </div>
                        <div className="row" style={{marginTop:'2rem'}} >
                            <div className="d-flex justify-content-center col-md">
                                <img src={select}/>
                            </div>
                        </div>
                        <div className="row" style={{marginTop:'2rem'}} >
                            <div className="d-flex justify-content-center col-md">
                                <p className="screen-description" style={{width:'70%'}} >{data.question}</p>
                            </div>
                        </div>
                        <div className="row" style={{marginTop:'2rem', paddingLeft:'10%', paddingRight:'10%'}} >
                            {optionsContent}
                        </div>
                    </div>
                    <Pulmikas screen={true}/>
                    <ScreenStepper
                        data={data}
                    />

                </div>
            </div>
        );
    }
}