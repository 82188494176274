import React from 'react';
import thumbDown from "static/svg/thumb-down.svg";
import thumbUp from "static/svg/thumb-up.svg";
import leaveGame from "static/svg/leave-game.svg";
import {FormattedMessage} from 'react-intl'
import ClickNHold from 'react-click-n-hold';

export default class DebateModeration extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            paused: false,
            disable_next: false,
        };

        this.interval;
        this.acceptDebate = this.acceptDebate.bind(this);
        this.declineDebate = this.declineDebate.bind(this);
    }

    declineDebate() {
        this.props.sendSocketMessage({
            command: 'multiQuestion.decline'
        });
    }

    acceptDebate() {
        this.props.sendSocketMessage({
            command: 'multiQuestion.approve'
        });
    }

    render() {
        let {groups} = this.props.information.data;
        let {data} = this.props.information;
        let debateGroup = groups[data.debate_group]
        let group_text = "";
        let name;


        if(debateGroup.name === null || debateGroup.name == ""){
            group_text = <FormattedMessage id = "group"/>
            name = data.debate_group
        }
        else {
            name = debateGroup.name;
        }

        if(name.length > 12){
            name = name.slice(0,11)+'...'
        }

        return (
            <div className="app-container">
            <div className="container-fluid facilitator-container scrollable-container">
                    <span className="exit-button" onClick={() => this.props.exitGame() }><img style={{width:"100%", height:"100%"}} src={leaveGame}/></span>      
                <div className="row">
                        <div className="title-container col-md" style={{marginTop:'5rem', paddingTop:0, marginBottom:0,}}>
                            <h1 className="app-title"><FormattedMessage id='facilitator.multiquestion.debatemoderation.title'/></h1>
                        </div>
                </div>
                <div className="row">
                        <div className="title-container col-md" style={{marginTop:'5rem', paddingTop:0, marginBottom:0,}}>
                            <p className="app-paragraph" style={{fontSize:'5rem'}}><FormattedMessage id='facilitator.multiquestion.debatemoderation.speaking'/></p>
                        </div>
                </div>
                <div className="row">
                        <div className="title-container col-md" style={{ paddingTop:0, marginBottom:0,}}>
                            <h1 className="app-title" style={{fontSize:'7rem'}}>{group_text} {name}</h1>
                        </div>
                </div>
                <div className="row">
                        <div className="d-flex justify-content-center col-md" style={{marginTop:'10rem', paddingRight:'15%', paddingLeft:'15%', marginBottom:'5rem'}}>
                        <p className="app-paragraph" style={{fontSize:'5rem'}}><FormattedMessage id='facilitator.multiquestion.debatemoderation.instruction'/></p>
                        </div>
                </div>
                <div className="row">
                    <div className="d-flex justify-content-center col-md">
                        <ClickNHold time={0.5}  onClickNHold={ () => this.acceptDebate() }>
                            <button className="accept-button noselect" ><img src={thumbUp}/><FormattedMessage id='facilitator.multiquestion.debatemoderation.accepted'/></button>
                        </ClickNHold>
                </div>
                </div>
                <div className="row">
                    <div className="d-flex justify-content-center col-md" >
                        <ClickNHold time={0.5} onClickNHold={ () => this.declineDebate() }>
                            <button className="decline-button noselect" onClick={this.declineDebate} ><img src={thumbDown}/><FormattedMessage id='facilitator.multiquestion.debatemoderation.declined'/></button>
                        </ClickNHold>
                    </div>
                </div>
            </div>
            </div>
        );
    }
}