import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import ScreenStepper from '../../../utils/ScreenStepper.jsx'

export default class GameStart extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let {data} = this.props.information
        let {groups} = this.props.information.data;

        return (
            <div className="screen-container">
                <div className="container-fluid">
                    <p className="screen-gameid-small"> <FormattedMessage id = "app.gameselection.gameid"/>: {this.props.information.data.player_code} </p>
                    <div className="row" style={{marginTop: '20rem'}}>
                        <div className="d-flex justify-content-center col-md">
                            <h1 className="screen-title" style={{fontSize: '10rem'}}> {data.title} </h1>
                        </div>
                    </div>
                    <ScreenStepper
                        data={data}
                    />

                </div>
            </div>
        );
    }
}