import React, {Component} from 'react';
import leaveGame from "static/svg/leave-game.svg";
import {Link} from 'react-router-dom';
import {
    Accordion,
    AccordionItem,
    AccordionItemPanel,
    AccordionItemButton,
    AccordionItemHeading
} from 'react-accessible-accordion';
import { FormattedMessage } from 'react-intl';
import ActionButtons from '../../../../utils/FacilitatorActions.jsx';

//Styles



export default class VoteModeration extends Component {
    constructor(props) {
        super(props);
    }

    render(){
        let {groups} = this.props.information.data;
        let groups_ready = 0;
        let amount_groups = Object.keys(groups).length;
        let groupList;
        let actionButtons;
        let infoText = <FormattedMessage id='facilitator.openquestion.vote' />;
        let info = (
            <div className="d-flex justify-content-center col-md">
                <p className="app-paragraph" style={{width: "70%", fontSize: "3rem", marginBottom: "3rem"}}>
                    {infoText}
                </p>
            </div>
        );

        groupList = Object.keys(groups).map((index) => {
            let group = groups[index];
            let group_text = "";
            let name = group.name;
            if(group.name === null || group.name == ""){
                group_text = <FormattedMessage id = "group"/>
                name = index
            }
            if(name.length > 13){
                name = name.slice(0,13) + '...'
            }
            if(group.votes_amount >= Object.keys(group.players).length){
                groups_ready++
            }
            return(
                <div className="row" style={{marginLeft:'7.5%', marginRight:'7.5%'}}>
                    <div className="d-flex justify-content-center col-md">
                        <Accordion
                        className="accordion-container"
                        >
                            <AccordionItem className="accordion-item">
                                <AccordionItemHeading
                                className="accordion-title-container"
                                >
                                    <AccordionItemButton>
                                        <h2 className="accordion-title" style={{display:'inline-block'}}>
                                            {group_text} {name}
                                        </h2>
                                        <span
                                            onClick={() => this.resetGroupName(index)}
                                            style={{display:'inline-block', lineHeight:'6rem', float:'right', marginRight:'2rem', fontSize:'2rem', fontFamily:'Source Sans Pro'}}
                                            className="accordion-icon">
                                            {group.votes_amount ? group.votes_amount : 0} <FormattedMessage id='facilitator.factquestion.answermoderation.answers' />
                                        </span>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                            </AccordionItem>
                        </Accordion>
                    </div>
                </div>
            )
        });
        actionButtons = (
            <ActionButtons
                information={this.props.information}
                sendSocketMessage={this.props.sendSocketMessage}
            />
        );
        return (
            <div className ="app-container">
                <div className="container-fluid team-wrapper facilitator-container  scrollable-container">
                    <span className="exit-button" onClick={() => this.props.exitGame() }><img style={{width:"100%", height:"100%"}} src={leaveGame}/></span>
                    <div className="row">
                        <div className="d-flex justify-content-center col-md">
                            <h1 className="app-title" style={{paddingTop:'1em'}}><FormattedMessage id='facilitator.groupmoderation.title'/></h1>
                        </div>
                    </div>
                    {info}
                    <div className="row">
                        <div className="d-flex justify-content-center col-md">
                            <h1 className="app-title" >{groups_ready}/{amount_groups}</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="d-flex justify-content-center col-md">
                            <p className="app-paragraph" style={{width:'70%', fontSize:'4rem', marginBottom:'3rem'}}><FormattedMessage id='facilitator.groupmoderation.instruction'/></p>
                        </div>
                    </div>
                    {groupList}
                </div>
                {actionButtons}
            </div>
        );
    }
}