import React, {Component} from 'react';
import ClickNHold from 'react-click-n-hold';
import {FormattedMessage} from 'react-intl';


export default class FacilitatorActions extends Component {
    constructor(props) {

        super(props);
        this.state = {
            disable_next: false,
            countdown: false,
        };
        this.interval;
        this.nextState = this.nextState.bind(this);
        this.countdown = this.countdown.bind(this);
        this.nextButtonEnded = this.nextButtonEnded.bind(this);
    }

    componentDidMount(){
        if ( this.state.countdown == false ){
            if(this.props.information.data.duration){
                this.state.countdown=true;
                clearInterval(this.interval);
                this.interval = setInterval((this.countdown), 1000);
            }
        }
    }

    componentWillUnmount(){
        clearInterval(this.interval);
    }

     componentDidUpdate(nextProps){

        if(nextProps.information.data.duration != this.props.information.data.duration){
            if(this.props.information.data.duration){
                this.state.paused=false;
                this.state.countdown=true;
                clearInterval(this.interval);
                this.interval = setInterval((this.countdown), 1000)
            }
        }

        if(document.getElementById('ended-not-enough') && document.getElementById('ended-not-enough').style.display !== "none") {
             document.getElementById('ended-not-enough').style.display = "none";
        }


        else if(this.state.countdown==false)
        {
            this.state.countdown=true;
            clearInterval(this.interval);
            if(nextProps.information.data.duration){
                this.state.paused = false;
                document.getElementById('countdown-number').innerHTML=nextProps.information.data.duration;
                this.interval = setInterval((this.countdown), 1000);
            }
        }
    }

    countdown(){
        let element = document.getElementById('countdown-number');
        if(element.innerHTML == 0){
            this.nextState();
        } else {
            element.innerHTML = element.innerHTML -1;
        }
    }

    nextButtonEnded(e, enough) {
       if(!enough) {
           document.getElementById('ended-not-enough').style.display = "block";
        }
    }

    nextState() {

        let countdown_element =document.getElementById('countdown');
        if (countdown_element) {
            countdown_element.style.display = "none";
        }

        clearInterval(this.interval);
        this.props.sendSocketMessage({
            command: 'game.next'
        });
        this.setState({disable_next: true});
        this.setState({countdown:false});
        setTimeout(()=> this.setState({disable_next:false}), 2000);
    }

    render(){
        let duration = this.props.information.data.duration ? this.props.information.data.duration : 0;
        let actionButtons;

        if (this.props.information.data.duration > 0) {
            actionButtons = (
                <div>
                    <div className="cnh-container">
                     <div id="ended-not-enough" style={{display: "none"}}><FormattedMessage id="press1second" /></div>
                        <ClickNHold time={0.5} onClickNHold={ () => this.nextState() }>
                            <button className="orange-button noselect">
                                <FormattedMessage id = "forward"/>
                                <span id="countdown"> (<span id="countdown-number">{duration}</span>)</span>
                            </button>
			            </ClickNHold>
                    </div>
                </div>
            )
        }
        else {
            actionButtons = (
            <div>
                 <div className="cnh-container">
                     <div id="ended-not-enough" style={{display: "none"}}><FormattedMessage id="press1second" /></div>
                     <ClickNHold time={0.5} onClickNHold={ () => this.nextState() } onEnd={ this.nextButtonEnded }>
                         <button className="orange-button noselect" >
                             <FormattedMessage id = "forward"/>
                         </button>
                     </ClickNHold>
                 </div>
            </div>
            );
        }
        return (
            <div className="actions-row">
                {actionButtons}
            </div>
        );
    }
}